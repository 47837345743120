<template>
    <div>
        <header-portrate />

    <div class="pa-2 m-2">
        <bread-crumbs :items="items" />
        <tabs-comp :tabs="tabs" />
        <v-row :style="`direction:`+lang.ldir+`;text-align:`+lang.lalgin">
            <v-col cols="12" md="12" sm="12">
                <!-- <b-button variant="success" class="btn-sm" :style="`width:145px;margin-`+lang.algin+`:6px;`" flat v-b-toggle.add_outreceipt color="success">{{lang.add_voutchre}}</b-button> -->
                <!-- <b-button variant="info" @click="ExportIt('e')" class="btn-sm" style="width:145px;margin-right:6px;">
                   تصدير اكسل
                </b-button> --> 
                <!-- <b-button variant="danger" class="btn-sm" style="width:145px;margin-right:6px;">PDF تصدير</b-button> -->
            </v-col>
        </v-row>
        
        <template>
        <v-data-table
            :headers="headers"
            :items="outrows"
            :items-per-page="10"
            class="elevation-1"
            style="padding-left:30px;padding-right:30xp;"
        >
            <template v-slot:item="row">
              <tr>
                <td class="text-center">{{ row.item.received_from }}</td>
                <td class="text-center">{{ row.item.receipt_date.substr(0,10) }}</td>
                <td class="text-center">{{ row.item.pay_reson }}</td>
                <td class="text-center">{{ row.item.total }}</td>
                <td class="text-center">{{ row.item.vat }}</td>
                <td class="text-center">{{ row.item.ftotal }}</td>
                <td class="text-center" style="width:60px;background:#902626;color:#FFF">
                    <v-btn style="font-size:0.2em;background:#902626;width:60px;color:#FFF;height:25px !important;" @click="printPDF(row.item.id)">{{lang.print}}&nbsp; &nbsp; <i class="fas fa-caret-left"></i></v-btn>
                </td>
              </tr>
            </template>
        </v-data-table>
        
        </template>
        <v-row :style="`direction:`+lang.ldir+`;margin-top:5px;margin-`+lang.lalgin+`:5px`">
            <v-col cols="12" md="4" sm="6" style="background:#eee;border:1px solid #ccc">
                <v-row :style="`direction:`+lang.dir">
                    <v-col col="6" :style="`text-align:`+lang.lalgin">{{lang.total}}</v-col>
                    <v-col class="text-center" cols="6">{{ all_total }}</v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row :style="`direction:`+lang.ldir+`;margin-`+lang.lalgin+`:5px`">
            <v-col cols="12" md="4" sm="6" style="background:#eee;border:1px solid #ccc">
                <v-row :style="`direction:`+lang.dir">
                    <v-col col="6" :style="`text-align:`+lang.lalgin">{{lang.vat}}</v-col>
                    <v-col class="text-center" cols="6">{{ all_vat }}</v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row :style="`direction:`+lang.ldir+`;margin-`+lang.lalgin+`:5px`">
            <v-col cols="12" md="4" sm="6" style="background:#eee;border:1px solid #ccc">
                <v-row :style="`direction:`+lang.dir">
                    <v-col col="6" :style="`text-align:`+lang.lalgin">{{lang.ftotal}}</v-col>
                    <v-col class="text-center" cols="6">{{ all_ftotal }}</v-col>
                </v-row>
            </v-col>
        </v-row>
        <outReceipt />
    </div>

        <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import outReceipt from '../components/outReceipt.vue'
import BreadCrumbs from '@/components/breadCrumbs.vue'
import TabsComp from '@/components/tabsComp.vue'
import HeaderPortrate from '@/components/Header-Portrate.vue'
import Footer from '@/components/Footer.vue'
export default{
    components:{
        outReceipt,
        BreadCrumbs,
        TabsComp,
        Footer,
        HeaderPortrate
    },
    data() {
     return {
            
            outrows: [
                
            ],
            all_total: 0,
            all_ftotal: 0,
            all_vat: 0,
            active_tab:1,
        }
    },
    created() {
        this.getVouchers();
        setInterval(() => {
            this.getVouchers()
        }, 10000);
    },
    methods: {
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print.php?recpid='+id;
        },
        printMe(item){
            const post = new FormData();
            post.append('export','inrecepit');
            post.append('type',1);
            post.append('id',item.id);
        },
        ExportIt(type){
            let url = this.$store.state.SAMCOTEC.e_path+'?';
            if(type != 'e'){
                let url = this.$store.state.SAMCOTEC.pd_path+'?inrecepit';
            }
            const post = new FormData();
            post.append('export','inrecepit');
            post.append('type',1);
            post.append('sdate','');
            post.append('edate','');
            axios.post(
                url, post
            ).then((res) => {
                var rest = res.data;
                window.open('../api' + rest.url,'_blank')
            })
        },
        getVouchers(){
            const post = new FormData();
            post.append("type" , 'getVoucher');
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase));
            post.append('data[sdate]',this.sdate);
            post.append('data[todate]',this.todate);
            post.append('data[type]',2);
            let all_total = 0;
            let all_ftotal = 0;
            let all_vat = 0;
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res.results.data != false)
                    {
                        this.outrows = res.results.data;
                   
                        if(this.outrows.length != 0){
                            for(let i = 0;i<this.outrows.length ; i++){
                                all_total = parseFloat(all_total) + parseFloat(this.outrows[i].total);
                                all_ftotal = parseFloat(all_ftotal) + parseFloat(this.outrows[i].ftotal);
                                all_vat = parseFloat(all_vat) + parseFloat(this.outrows[i].vat);
                            }
                        }
                    }
                    this.all_total = this.$RoundNum(all_total);
                    this.all_ftotal = this.$RoundNum(all_ftotal);
                    this.all_vat = this.$RoundNum(all_total);
                }
            )
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tabs: function(){
            let tabs =  [
                {
                    index:3,name:this.lang.vat_report,href:'/reports/vat-reports',class:'mytab4',smclass:'font-small'
                },
                
            ];
          if(this.groupid != 3 && !this.$store.state.licenseType.onlySalesPurchase) {
            tabs.push(
                {
                  index: 4,
                  name: this.lang.income_report,
                  href: '/reports/income-report',
                  class: 'mytab3',
                  smclass: 'font-small'
                }
            );
            tabs.push(
                {
                  index: 7,
                  name: this.lang.account_statement,
                  href: '/reports/statment-report',
                  class: 'mytab3',
                  smclass: 'font-small'
                }
            );

            tabs.push(
                {
                  index: 8,
                  name: this.lang.account_balance_report,
                  href: '/reports/balance-report',
                  class: 'mytab3',
                  smclass: 'font-small'
                }
            );
            tabs.push(
                {
                  index: 8,
                  name: this.lang.bnl_report,
                  href: '/reports/bnl-report',
                  class: 'mytab3',
                  smclass: 'font-small'
                }
            );
            tabs.push(
                {
                  index: 8,
                  name: this.lang.balance_report,
                  href: '/reports/acbalance-report',
                  class: 'mytab3',
                  smclass: 'font-small'
                }
            );
            tabs.push(
                {
                  index: 2,
                  name: this.lang.sales_report,
                  href: '/reports/sales-reports',
                  class: 'mytab5',
                  smclass: 'font-small'
                }
            );
            tabs.push({
              index: 5,
              name: this.lang.return_report,
              href: '/reports/return-reports',
              class: 'mytab6',
              smclass: 'font-small'
                }
            );
          }
          if(this.groupid != 3) {
            tabs.push({
              index: 0,
              name: this.lang.received_voucher,
              href: '/finance/inreceipt',
              class: 'mytab4',
              smclass: 'font-small'
                }
            );
            tabs.push({
              index: 1,
              name: this.lang.exchange_voucher,
              href: '/finance/outreceipt',
              class: 'mytab',
              smclass: 'font-small'
                }
            );
          }
            return tabs;
        },
        items: function() {
            return {
                    text: this.lang.exchange_voucher,
                    disabled: true,
                    to: '/finance/outreceipt',
                }
        },
        headers: function(){ 
            return [
                { text: this.lang.receive_by, value: 'received_by',sortable: false, },
                { text: this.lang.date, value: 'receipt_date',sortable: false, },
                { text: this.lang.voutcher_resone, value: 'pay_reson',sortable: false,},   
                { text: this.lang.total, value: 'total',sortable: false, },
                { text: this.lang.vat + ' 15%', value: 'vat',sortable: false, },
                { text: this.lang.ftotal, value: 'ftotal',sortable: false, },
                { text: this.lang.action, value: 'ftotal',sortable: false, },
            ]
        }
    },
}
</script>